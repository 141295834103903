.subcomponentContainer {
    text-align: center;
    margin-right: 70px;
    margin-left: 70px;
    padding-top: 90px;
    padding-bottom: 90px;
    font-family: 'Open Sans';
}











.header p{
    color: #EA6122;
    font-size: 25px;
    font-family: 'Open Sans';
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
}

.header span{
    font-size: 15px;
    font-family: 'Open Sans';
    font-weight: 300;
}

.columnsContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    width: 100%;
}

.upperDiv {
    margin-left: 35%;
    margin-bottom: 10px;
    color: #2C2C2C;
    margin-right: 70px;
    text-align: center;
    font-size: 25px;
    font-family: 'Open Sans';
    font-weight: 600;
    text-transform: uppercase;
}

.phoneLink {
    color: #2C2C2C;
    text-decoration: none;
}

.column {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.columnPartOf3 {
    flex-basis: 33%;
    text-align: center;
}

.subtitle {
    margin-top: 20px;
    color: #EA6122;
    text-align: center;
    font-size: 25px;
    font-family: 'Open Sans';
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@media (min-width: 901px) {
    .header {
        text-align: left;
        margin-bottom: 10px;
        color: #2C2C2C;
        margin-right: 70px;
    }
    .textSubTitle {
        text-align: left;
        padding-bottom: 20px;
        color: #EA6122;
        font-size: 25px;
        font-family: 'Open Sans';
        font-weight: 600;
    }
    .textSubText {
        text-align: left;
        padding-bottom: 15px;
        color: #2C2C2C;
        font-size: 20px;
        font-family: 'Open Sans';
        font-weight: 100;
    }
    .textSubNote {
        text-align: left;
        margin-bottom: 10px;
        color: #2C2C2C;
        font-size: 20px;
        font-family: 'Open Sans';
        font-weight: 400;
    }
    .tableText {
        margin-top: auto;
        font-size: 20px;
    }
    .subtitlePartOf3 {
        margin-top: 20px;
        color: #EA6122;
        text-align: center;
        font-size: 25px;
        font-family: 'Open Sans';
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .text {
        padding-top: 90px;
        text-align: left;
        margin-bottom: 10px;
        color: #2C2C2C;
        margin-right: 10%
    }
}

@media (max-width: 900px) {
    .header {
        text-align: center;
        justify-content: center; /* Change to center */
        align-items: center; /* Add align-items */
        margin-bottom: 10px;
        color: #2C2C2C;
    }
    .textSubTitle {
        text-align: center;
        padding-bottom: 20px;
        color: #EA6122;
        font-size: 25px;
        font-family: 'Open Sans';
        font-weight: 600;
    }
    .textSubText {
        text-align: center;
        padding-bottom: 15px;
        color: #2C2C2C;
        font-size: 20px;
        font-family: 'Open Sans';
        font-weight: 100;
    }
    .textSubNote {
        text-align: center;
        margin-bottom: 10px;
        color: #2C2C2C;
        font-size: 20px;
        font-family: 'Open Sans';
        font-weight: 400;
    }
    .subtitlePartOf3 {
        margin-top: 20px;
        color: #EA6122;
        text-align: center;
        font-size: 15px;
        font-family: 'Open Sans';
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .text {
        padding-top: 70px;
        text-align: center;
        margin-bottom: 10px;
        color: #2C2C2C;
    }

    .tableText {
        margin-top: auto;
        font-size: 13px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }
    .subtitle {
        margin-top: 20px;
        color: #EA6122;
        text-align: center;
        font-size: 20px;
        font-family: 'Open Sans';
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}
