.contentContainer {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    width: 100%;
    margin-top: 30px;
    overflow-x: hidden;
}

.title {
    text-align: center;
    color: #EA6122;
    font-weight: 700;
    font-size: 55px;
  }

.buttonSignUp {
    border-radius: 50px;
    background: #EA6122;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: 'Open Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    justify-content: center;
    padding: 10px;
    border: none;
    cursor: pointer;
}

.subDescription {
  font-family: 'Open Sans';
  font-style: normal;
  color: #2C2C2C;
  text-align: center;
  font-size: 25px;
  font-weight: 400;
    margin-left: 90px;
    margin-right: 90px;
}

.subDescriptionSmall {
    font-family: 'Open Sans';
    font-style: normal;
    color: #2C2C2C;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
      margin-left: 90px;
      margin-right: 90px;
  }

.descriptionAndImage {
    color: #2C2C2C;
    font-size: 20px;
    font-style: normal;
    font-family: 'Open Sans';
    display: flex;
    flex-shrink: 0;
    flex-direction: row-reverse;
    align-items: center;
    margin-left: 70px;
    margin-right: 70px;
    margin-bottom: 50px;
}

.orangeText {
    color: #EA6122;
    cursor: pointer;
    font-weight: 900;
    font-size: 25px;
}

.textContent, .imageContent {
    flex: 1 1;
    padding: 20px;
    box-sizing: border-box;
    justify-content: center;
}

.imageContent {
    display: flex; /* Use flexbox for image container */
    align-items: center; /* Center image vertically */
}

.imageContent img {
    width: 100%;
    object-fit: cover;
}

@media (max-width: 900px) {
    .contentContainer {
        width: 100%;
        margin-top: 30px;
        align-items: center;
        text-align: center;
    }

    .subDescription {
        font-size: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .subDescriptionSmall {
        font-size: 15px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .descriptionAndImage {
        flex-direction: column;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 30px;
    }
    .textContent, .imageContent {
        flex-basis: 100%;
    }
    .columnsContainer {
        margin-top: 10px;
    }

    .title {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 35px;
      }

    .upperDiv,
    .upperDivLeft,
    .underDiv {
        margin-left: 5%;
        margin-right: 5%;
        font-size: 20px;
    }

    .subtitle {
        font-size: 20px;
    }
}

