.container {
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    color: #2C2C2C;
    width: 100%;
}

.title {
    text-align: center;
    color: #EA6122;
    font-weight: 700;
    font-size: 55px;
}

.columnsContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.backgroundCircle {
    width: 100%;
    /*padding-bottom: 100%; !* Maintain 1:1 aspect ratio *!*/
    background-color: #EA6122;
    border-radius: 50%;
}
@media (min-width: 901px) {
    .upperDiv {
        margin-left: 35%;
        margin-bottom: 10px;
        color: #2C2C2C;
        margin-right: 70px;
        text-align: center;
        font-size: 25px;
        font-family: 'Open Sans';
        font-weight: 600;
        text-transform: uppercase;
    }
    .underDiv {
        margin-left: 35%;
        margin-bottom: 10px;
        margin-right: 100px;
        text-align: left;
        font-size: 15px;
        font-family: 'Open Sans';
        color: #000;
        font-weight: 300;
    }
    .upperDivLeft {
        margin-top: 100px;
        margin-left: 15%;
        margin-bottom: 10px;
        color: #2C2C2C;
        margin-right: 70px;
        text-align: left;
        font-size: 25px;
        font-family: 'Open Sans';
        font-weight: 600;
        text-transform: uppercase;
    }

}


.orangeText {
    color: #EA6122;
}

.column1,
.column2,
.column3 {
    flex: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tableText {
    margin-top: auto;
    font-size: 20px;
}

.subtitle {
    margin-top: 20px;
    color: #EA6122;
    text-align: center;
    font-size: 25px;
    font-family: 'Open Sans';
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@media (max-width: 900px) {

    .upperDivLeft {
        margin-bottom: 10px;
        color: #2C2C2C;
        text-align: center;
        font-size: 25px;
        font-family: 'Open Sans';
        font-weight: 600;
        text-transform: uppercase;
    }

    .subtitle {
        margin-top: 20px;
        color: #EA6122;
        text-align: center;
        font-size: 15px;
        font-family: 'Open Sans';
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }

    .tableText {
        margin-top: auto;
        font-size: 13px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .columnsContainer {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 10px;
        margin-right: 5px;
    }


    .column1,
    .column2,
    .column3 {
        flex-basis: 33%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .upperDiv {
        margin-bottom: 10px;
        color: #2C2C2C;
        text-align: center;
        justify-content: center; /* Change to center */
        align-items: center; /* Add align-items */
        font-size: 15px;
        font-family: 'Open Sans';
        font-weight: 600;
        text-transform: uppercase;
    }

    .underDiv {
        margin-bottom: 10px;
        margin-right: 10px;
        margin-left: 10px;
        font-size: 15px;
        font-family: 'Open Sans';
        text-align: center;
        justify-content: center; /* Change to center */
        align-items: center; /* Add align-items */
        color: #000;
        font-weight: 300;
    }
}



