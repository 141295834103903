/* Footer.module.css */

.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #EE7828;
    min-height: 250px;
  }

  .logo img {
    width: 200px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
  }

  .socialIcon {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    cursor: pointer;
  }

  .socialIcon:hover {
    opacity: 0.8;
  }

  .contactDetails {
    flex: 1 1 auto;
    text-align: center;
    color: white;
  }

  .phone,
  .email {
    margin-bottom: 10px;
  }

  .phoneLink,
  .emailLink {
    color: white;
    text-decoration: none;
  }

  .social {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px; /* Add some spacing for mobile */
  }

  @media screen and (min-width: 901px) {
    .logo {
      flex: 1;
      margin-left: 70px;
    }
    .social {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px; /* Add some spacing for mobile */
        margin-right: 50px;
      }
  }

  /* Mobile Styles */
  @media screen and (max-width: 900px) {
    .footer {
      flex-direction: column;
      width: 100%;
      min-height: 400px;
    }

    .logo {
      margin-top: 20px;
    }

    .logo,
    .contact,
    .social {
      flex: 1;
      text-align: center;
    }

    .logo img {
      width: 80px; /* Adjust the width for mobile */
    }

    .socialButton {
      margin-left: 10px; /* Adjust the spacing between buttons */
      background-color: white;
      border: none;
      padding: 5px 10px;
      cursor: pointer;
      /* Add additional button styles as needed */
    }
  }
