.contentContainer {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    width: 100%;
    margin-top: 130px;
    overflow-x: hidden;
}

.subDescription {
  font-family: 'Open Sans';
  font-style: normal;
  color: #2C2C2C;
  text-align: center;
  font-size: 25px;
  font-weight: 300;
    margin-left: 90px;
    margin-right: 90px;
}

.descriptionAndImage {
    color: #2C2C2C;
    font-size: 20px;
    font-style: normal;
    font-family: 'Open Sans';
    display: flex;
    flex-shrink: 0;
    flex-direction: row-reverse;
    align-items: center;
    margin-left: 70px;
    margin-right: 70px;
    margin-bottom: 50px;
}

.orangeText {
    color: #EA6122;
}

.textContent, .imageContent {
    flex: 1 1;
    padding: 20px;
    box-sizing: border-box;
    justify-content: center;
}

.imageContent {
    display: flex; /* Use flexbox for image container */
    align-items: center; /* Center image vertically */
}

.imageContent img {
    width: 100%;
    object-fit: cover;
}

@media (max-width: 900px) {
    .contentContainer {
        width: 100%;
        margin-top: 80px;
        align-items: center;
        text-align: center;
    }

    .subDescription {
        font-size: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .descriptionAndImage {
        flex-direction: column;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 30px;
    }
    .textContent, .imageContent {
        flex-basis: 100%;
    }
    .columnsContainer {
        margin-top: 10px;
    }

    .upperDiv,
    .upperDivLeft,
    .underDiv {
        margin-left: 5%;
        margin-right: 5%;
        font-size: 20px;
    }

    .subtitle {
        font-size: 20px;
    }
}

