.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-position-y: bottom;
  object-fit: cover;
}




.logoContainer {
  flex: 0 0 auto;
  padding-right: 10px;
}

.logo {
  width: 100px;
  height: auto;
  z-index: 1000; /* Place the content above the background image */
}

@media (min-width: 901px) {
  .contentContainer {
    text-align: left;
    width: 50%;
    color: #FFF;
    font-size: 80px;
    font-family: 'Open Sans';
    font-weight: 600;
    flex: 1 0 auto;
    z-index: 1000; /* Place the content above the background image */
    margin-left: 70px;
    margin-top: 70px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    min-height: 600px;
    max-height: 680px;
    position: relative;
    background-color: #f2f2f2;
  }
}

.heading {
  margin: 0;
  color: #FFFFFF;
  font-size: 70px;
  font-weight: 500;
}

.subtext {
  margin: 0;
  color: gray;
}

.menuContainer {
  padding-top: 40px;
  padding-right: 70px;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  z-index: 1; /* Place the menu above the background image */
}

.menuItems {
  display: flex;
  gap: 10px;
}

.menuItems a {
  color: black;
  text-decoration: none;
}

.mobileMenuIcon {
  display: none;
}

.bar {
  width: 20px;
  height: 2px;
  background-color: black;
  margin-bottom: 4px;
}

.mobileMenuOpen .bar:nth-child(1) {
  transform: translateY(6px) rotate(45deg);
}

.mobileMenuOpen .bar:nth-child(2) {
  opacity: 0;
}

.mobileMenuOpen .bar:nth-child(3) {
  transform: translateY(-6px) rotate(-45deg);
}

@media (max-width: 900px) {
  .logo {
    width: 80px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    min-height: 600px;
    max-height: 680px;
    position: relative;
    background-color: #f2f2f2;
  }

  .contentContainer {
    text-align: center;
    margin-top: 100px;
    width: 100%;
    color: #FFF;
    font-size: 80px;
    font-family: 'Open Sans';
    font-weight: 600;
    flex: 1 0 auto;
    z-index: 1000; /* Place the content above the background image */
  }


  .heading {
    margin: 0;
    color: #FFFFFF;
    font-size: 60px;
    font-weight: 700;
    margin-right: 15px;
  }

  .contentContainer {
    text-align: center;
  }

  .menuItems {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 40px;
    right: 10px;
    background-color: #f2f2f2;
    padding: 10px;
  }

  .mobileMenuOpen .menuItems {
    display: flex;
  }

  .mobileMenuIcon {
    display: block;
    cursor: pointer;
  }
}
