@import url("https://fonts.googleapis.com/css2?family=Goldman:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-open-sans: "Open Sans" !important;
  --font-goldman: Goldman;

  /* font sizes */
  --font-size-81xl: 100px;
  --font-size-151xl: 170px;
  --font-size-61xl: 80px;
  --font-size-131xl: 150px;
  --font-size-281xl: 300px;

  /* Colors */
  --color-white: #fff;
  --color-chocolate: #ee7828;
  --color-gray: #2c2c2c;
}
