.componentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Open Sans';
  font-style: normal;
  color: #2C2C2C;
  text-align: center;
  font-weight: 300;
  padding-bottom: 100px;
  width: 100%;
}

.title {
  text-align: center;
  color: #EA6122;
  font-weight: 700;
  font-size: 55px;
}

.columnsContainer {
  display: flex;
  justify-content: center;
  gap: 100px;
  margin-top: 20px;
  padding-left: 70px;
  padding-right: 70px;
}

.column {
  flex: 1; /* Equal width for each column */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

}

.phone {
  text-decoration: underline;
  color: #2C2C2C;
  font-weight: 300;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 20px;
}

.phoneLink {
  text-decoration: none;
  color: #2C2C2C;
  font-weight: 300;
  font-family: 'Open Sans';
  font-style: normal;
}

@media screen and (max-width: 900px) {
  .columnsContainer {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .column {
    width: 100%;
  }
}
