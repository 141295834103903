.componentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Open Sans';
  font-style: normal;
  color: #2C2C2C;
  text-align: center;
  font-weight: 300;
  padding-left: 70px;
  padding-right: 70px;
}

.title {
  text-align: center;
  color: #EA6122;
  font-weight: 700;
  font-size: 55px;
}

.smallBox {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #2C2C2C;
  font-weight: 400;
  font-size: 25px;
  padding-top: 10px;
  padding-bottom: 50px;
}

.subText {
  color: #2C2C2C;
  font-weight: 100;
  font-size: 20px;
  height: 110px;
}

.columnsContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.column {
  flex: 1; /* Equal width for each column */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.imageContainer {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 900px) {
  .columnsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
  }

  .column h2 {
    color: #EA6122;
    margin-bottom: 10px;
  }

  .column img {
    width: 100%;
    max-width: 300px;
  }

  .smallBox {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #2C2C2C;
    font-weight: 400;
    font-size: 25px;
    margin-right: 20px !important;
    margin-left: 20px !important;
    padding-top: 10px;
    padding-bottom: 50px;
  }

  .componentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Open Sans';
    font-style: normal;
    color: #2C2C2C;
    text-align: center;
    font-weight: 300;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

